import React from 'react';

export default class HalfImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    const data = this.props.data;

    const shadowStyle = {
      boxShadow: data.shadow ? "4px 4px 16px #cdcdcd" : "none",
      borderRadius: data.rounded ? "6px" : "none",
      width: data.size ? data.size : "100%"
    }
    console.log(shadowStyle);

    const holderStyle = {
      textAlign: data.size ? "center" : ""
    }

    return (
      <div className="half-image-holder">
        <div className="half-image-main">
          <div className="half-image-left">
            <h2 className="half-title">{data.title}</h2>
            <p className="half-description">{data.description}</p>
          </div>
          <div className="half-image-right" style={holderStyle}>
            <img className="image" src={data.src} alt={data.alt} style={shadowStyle} />
          </div>
        </div>
      </div>
    )
  }
}
