import React from 'react';

export default class FullCard extends React.Component {
  constructor(props) {
    super(props);
    this.triggerAnimation = this.triggerAnimation.bind(this);
    this.state = {
      animation: false,
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.triggerAnimation);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.triggerAnimation);
  }
  triggerAnimation() {
    // when it hits the middle 50 of viewport, transform up by adding classname
    const card = document.getElementById(this.props.id);
    let bounds = card.getBoundingClientRect();
    let mid50bottom = window.innerHeight - (window.innerHeight / 4);
    let mid50top = window.innerHeight / 4;
    let detailTop = bounds.top;
    let half = window.innerHeight / 2;

    // on desktop, we use the middle 50% to fade in or out
    if (window.innerWidth >= 900) {
      if (((detailTop > mid50top) && (detailTop < mid50bottom)) || (detailTop < mid50top)) {
        this.setState({ animation: true });
      }
    }
    // on mobile, once it hits middle of screen we fade in
    else {
      if (detailTop <= half) {
        this.setState({ animation: true });
      }
    }
  }

  render() {
    const data = this.props.cardData;
    let classes = "card-full ";
    classes += this.state.animation ? "card-animated" : "";

    return (
      <div className={classes} id={this.props.id}>
        <div className="card-left">
          <div className="icon-holder">
            <img className="card-icon" src={data.icon} alt="" />
            <h2 className="icon-title">{data.iconTitle}</h2>
          </div>
          <h1 className="card-title">{data.cardTitle}</h1>
          <p className="card-p">{data.description}</p>
        </div>
        <div className="card-right">
          <img className="card-image" src={data.image} alt="" />
        </div>
      </div>
    );
  }
}
