import React from 'react';

export default class HomeTextHero extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const textItems = this.props.data.textItems;
    return (
      <div className="home-text-hero-holder">
        <div className="home-text-hero-inner">
          <div className="home-text">
            {textItems.map((item, idx) => {
              let classes = item.type === "normal" ? "text text-normal " : "text text-gradient ";
              classes += item.animation;
              return (
                <p className={classes}>{item.text}</p>
              );
            })}
          </div>
        </div>
      </div>
    )
  }
}
