import React from 'react';

import DetailHolder from "./DetailHolder";
import Phone from './Phone';

export default class PhoneFade extends React.Component {
  constructor(props) {
    super(props);
    this.resizeWindow = this.resizeWindow.bind(this);
    this.updatePhones = this.updatePhones.bind(this);
    this.state = {
      // if mobile, then first also fades in
      phones: (window.innerWidth >= 900 ? [true, false, false, false] : [false, false, false, false]),
      windowWidth: window.innerWidth,
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.resizeWindow);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeWindow);
  }
  resizeWindow() {
    this.setState({ windowWidth: window.innerWidth });
  }
  updatePhones(phoneToShow) {
    let phoneIDX = phoneToShow[phoneToShow.length - 1];
    let newPhones = Array.from(this.state.phones);

    // only do a fade out on desktop
    if (this.state.windowWidth >= 900) {
      newPhones.fill(false);
    }
    newPhones[phoneIDX] = true;
    this.setState({
      phones: newPhones,
    });
  }

  render() {

    const { phoneType, details, images } = this.props;

    const holderName = phoneType + "-holder";

    return (
      <div className="phone-fade-wrapper">
        <div className="sticky-section-wrapper">
          {/* determine what to render */}
          {this.state.windowWidth >= 900 ?
          (<>
            <div className="not-sticky">
              {details.map((detail, idx) => {
                const id = "detailHolder" + idx;
                return (
                  <DetailHolder id={id} title={detail.tag} tagColor={detail.tagColor} subtitle={detail.title} detailText={detail.paragraphs} updatePhones={this.updatePhones}/>
                )
              })}
            </div>
            <div className="sticky">
              <div className={holderName}>
                {images.map((img, idx) => {
                  const id = "phone" + idx;
                  return (
                    <Phone id={id} shown={this.state.phones[idx]} type={phoneType} image={img} />
                  )
                })}
              </div>
            </div>
          </>)
          :
            <div className="mobile-sticky-section-wrapper">
              {details.map((detail, idx) => {
                const id = "detailHolder" + idx;
                const phoneID = "phone" + idx;
                const _class = "mobile-" + phoneType + "-holder";
                return (
                  <>
                    <DetailHolder id={id} title={detail.tag} tagColor={detail.tagColor} subtitle={detail.title} detailText={detail.paragraphs} updatePhones={this.updatePhones}/>
                    <div className={_class}>
                      <Phone id={phoneID} shown={this.state.phones[idx]} type={phoneType} image={images[idx]} />
                    </div>
                  </>
                )
              })}
            </div>
          }
        </div>
      </div>
    )
  }
}
