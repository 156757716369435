import React from 'react';
import { Link } from 'react-router-dom';

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.getYear = this.getYear.bind(this);
    this.state = {};
  }
  getYear() {
    return new Date().getFullYear();
  }
  render() {
    return (
      <div className="footer-holder">
        <div className="footer-top">
          <h1 className="footer-title">Jordan B. Wolff</h1>
          <h3 className="footer-subtitle">Program Manager, Microsoft</h3>
        </div>
        <div className="links-holder">
          <div className="link-collection">
            <h2 className="link-collection-title">Work</h2>
            <ul className="link-list">
              <li className="link"><Link to="/project/gradeguide">GradeGuide</Link></li>
              <li className="link"><Link to="/project/focus">Focus</Link></li>
              <li className="link"><Link to="/project/michigan-daily">The Daily</Link></li>
              <li className="link"><Link to="/project/shinola">Shinola</Link></li>
            </ul>
          </div>

          <div className="link-collection">
            <h2 className="link-collection-title">Info</h2>
            <ul className="link-list">
              <li className="link"><a href="mailto:hello@jordanbwolff.com" target="_blank" rel="noopener noreferrer">Contact</a></li>
              <li className="link"><Link to="/blog">Blog</Link></li>
              <li className="link"><a href="https://www.linkedin.com/in/jordanbwolff/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
              <li className="link"><a href="https://www.instagram.com/jordanbwolff/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
            </ul>
          </div>
        </div>

        <div className="footer-bottom">
          <p className="footer-legal">Copyright &copy; {this.getYear()}, Jordan Wolff. All rights reserved.</p>
          <p className="footer-legal">|</p>
          <p className="footer-legal"><a href="https://app.termly.io/document/privacy-policy/32ac1338-3884-4c33-8d20-397fb069aaf8" target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
        </div>
      </div>
    )
  }
}
