import React from 'react';
import SEO from '../SEO';
import homeData from "../data/home.json";

import HomeTextHero from '../components/HomeTextHero';
import Hero from '../components/Hero';
import Description from '../components/Description';
import FullCard from '../components/FullCard';
import CardDual from '../components/CardDual';
import NavBar from '../components/NavBar';
import PhoneFade from '../components/PhoneFade';
import SmallInfo from '../components/SmallInfo';
import SafariView from '../components/SafariView';
import LargeQuote from '../components/LargeQuote';
import Footer from '../components/Footer';
import ScrollIndicator from '../components/ScrollIndicator';

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.setHomeFavicon = this.setHomeFavicon.bind(this);
    this.state = {
      menuShown: false,
      data: homeData.home
    };
  }

  componentDidMount() {
    window.scrollTo(0,0); // go back to top of page
    // document.title = "Jordan B. Wolff";
    this.setHomeFavicon();
  }

  setHomeFavicon() {
    let favicon = document.getElementById("favicon-16");
    favicon.href = "/img/icon16.png";
    favicon = document.getElementById("favicon-32");
    favicon.href = "/img/icon32.png";
    favicon = document.getElementById("favicon-196");
    favicon.href = "/img/icon196.png";
  }

  render() {
    return (
      <>
        <SEO
          title="Jordan B. Wolff"
          thumbnail={"https://www.jordanbwolff.com/img/jordanbwolff.jpg"}
          thumbnailAlt="Jordan B. Wolff"
          pageDescription="Jordan Wolff is a Program Manager at Microsoft working in Azure, Core OS, and the Intelligent Edge."
          url={"https://www.jordanbwolff.com"}
        />
        <NavBar />
        <div className="home-holder">
          {this.state.data && this.state.data.map((section, idx) => {
            const bgStyle = section.bgImage 
            ? {
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right bottom",
              backgroundSize: "contain",
              backgroundImage: section.bgImage ? section.bgImage : "",
              boxShadow: "0px -1px 0px 0px #c6c6c6"
            }
            : {
              background: section.background,
              paddingTop: section.padding + "px",
              paddingBottom: section.padding + "px"
            }
            const customID = section.ID ? section.ID : null;
            return (
              <div id={customID} className="section" style={bgStyle}>
                {section.nodes.map((node, idx) => {
                  if (node.type === "home-text-hero") {
                    return (
                      <HomeTextHero data={node} />
                    )
                  }
                  else if (node.type.includes("hero")) {
                    return (
                      <Hero sectionData={node} />
                    );
                  }
                  else if (node.type === "description") {
                    return (
                      <Description alignment={node.alignment} title={node.title} paragraph={node.paragraph} animation={node.animation} size={node.size} icon={node.icon} />
                    )
                  }
                  else if (node.type === "card-full") {
                    return (
                      <FullCard cardData={node} id={"card-full-" + idx} />
                    )
                  }
                  else if (node.type === "card-dual") {
                    return (
                      <CardDual data={node} id={"card-dual-" + idx} />
                    );
                  }
                  else if (node.type === "phoneFade") {
                    return (
                      <PhoneFade phoneType={node.phoneType} details={node.details} images={node.images} />
                    )
                  }
                  else if (node.type === "small-info")  {
                    return (
                      <SmallInfo text={node.text} />
                    );
                  }
                  else if (node.type === "safariView") {
                    return (
                      <SafariView data={node} id={"safari-view-" + idx} />
                    );
                  }
                  else if (node.type === "large-quote") {
                    return (
                      <LargeQuote data={node} />
                    );
                  }
                  else if (node.type === "footer") {
                    return (
                      <Footer />
                    );
                  }
                  else if (node.type === "scroll-indicator") {
                    return (
                      <ScrollIndicator />
                    )
                  }
                  else {
                    return ("undefined")
                  }
                })}
              </div>
            )
          })}
        </div>
      </>
    );
  }
}
