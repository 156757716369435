import React from 'react';

export default class SmallInfo extends React.Component {
  render() {
    return (
      <div className="small-info-holder">
        <span className="small-info">{this.props.text}</span>
      </div>
    )
  }
}
