import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import './base.css';
import './stylesheets/style.css';

// Main Pages
import Home from './pages/Home';
// import Test from './pages/Test';
import Project from './pages/Project';

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        {/*<Route path="/test" component={Test} />*/}
        <Route path="/project/:id" component={Project} />
        <Route render={() => { return (<Redirect to="/" />); }} />
      </Switch>
    </Router>
  );
}
