import React from 'react';

export default class Description extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {

    const descriptionStyle = {
      textAlign: this.props.alignment,
    }

    let titleClasses = "description-title ";
    titleClasses += this.props.size === "small" ? "title-small" : "title-large";

    let pClasses = "description-p " + this.props.alignment + " ";
    pClasses += this.props.size === "small" ? "p-small" : "p-large";

    return (
      <div className="description-holder" style={descriptionStyle}>
        {this.props.icon &&
        <img className="description-icon" src={this.props.icon} alt={this.props.title + " photo"} />
        }
        {this.props.title !== "N/A" &&
        <h1 className={titleClasses} style={descriptionStyle}>{this.props.title}</h1>
        }
        {this.props.paragraph !== "N/A" &&
        <p className={pClasses}>{this.props.paragraph}</p>
        }
      </div>
    )
  }
}
