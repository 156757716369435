import React from 'react';

export default class DetailHolder extends React.Component {
  constructor(props) {
    super(props);
    this.triggerNextDiv = this.triggerNextDiv.bind(this);
    this.state = {};
  }

  componentDidMount() {
    window.addEventListener("scroll", this.triggerNextDiv);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.triggerNextDiv);
  }

  triggerNextDiv() {
    // if this holder is in the middle 50 of the viewport, then
    // update the phones to show this phone and hide all others
    let detail = document.getElementById(this.props.id);
    let bounds = detail.getBoundingClientRect();
    let mid50bottom = window.innerHeight - (window.innerHeight / 4);
    let mid50top = window.innerHeight / 4;
    let detailTop = bounds.top;
    let half = window.innerHeight / 2;

    // on desktop, we use the middle 50% to fade in or out
    if (window.innerWidth >= 900) {
      if (detailTop > mid50top && detailTop < mid50bottom) {
        this.props.updatePhones(this.props.id);
      }
    }
    // on mobile, once it hits middle of screen we fade in
    else {
      if (detailTop <= half) {
        this.props.updatePhones(this.props.id);
      }
    }
  }

  render() {

    const color = {
      color: this.props.tagColor,
    }

    return (
      <div className="detail-holder" id={this.props.id}>
        <h2 className="detail-title" style={color}>{this.props.title}</h2>
        <h3 className="detail-subtitle">{this.props.subtitle}</h3>
        {this.props.detailText.map((detail, idx) => {
          return (
            <p className="detail-text">{detail}</p>
          )
        })}
      </div>
    );
  }
}
