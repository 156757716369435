import React from 'react';
import SEO from '../SEO';
import { Redirect } from 'react-router-dom';
import allData from "../data/projects.json";

import Hero from '../components/Hero';
import Description from '../components/Description';
import FullCard from '../components/FullCard';
import CardDual from '../components/CardDual';
import NavBar from '../components/NavBar';
import PhoneFade from '../components/PhoneFade';
import SmallInfo from '../components/SmallInfo';
import SafariView from '../components/SafariView';
import FullImage from '../components/FullImage';
import HalfImage from '../components/HalfImage';
import TallImage from '../components/TallImage';
import Video from '../components/Video';
import LargeQuote from '../components/LargeQuote';
import Footer from '../components/Footer';

export default class Project extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allData: allData,
      redirect: false,
      projectID: null,
      projectData: null,
      projectInfo: null
    };
  }
  componentDidMount() {
    window.scrollTo(0,0); // go back to top of page
    const { id } = this.props.match.params;
    let data = this.state.allData[id]["data"];
    let info = this.state.allData[id]["info"];
    if (!data) {
      this.setState({ redirect: true });
    }
    else {
      this.setState({ projectData: data, projectID: id, projectInfo: info });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    // check to see if page changed
    const newId = this.props.match.params.id;
    const oldId = prevProps.match.params.id;
    if (newId !== oldId) {
      window.scrollTo(0,0); // go back to top of page
      window.location.reload(); // reload so animations play again
    }
  }

  componentWillUnmount() {

  }
  render() {
    if (this.state.redirect) {
      return (<Redirect to="/" />);
    }

    console.log(this.state.projectData);

    return (
      <>
        {this.state.projectInfo && this.state.projectID &&
          <SEO
            title={this.state.projectInfo.title}
            thumbnail={`https://www.jordanbwolff.com${this.state.projectInfo.thumbnail}`}
            thumbnailAlt={this.state.projectInfo.thumbnailAlt}
            pageDescription={this.state.projectInfo.pageDescription}
            url={`https://www.jordanbwolff.com/project/${this.state.projectID}`}
          />
        }
        <NavBar />
        <div className="project-holder">
          {this.state.projectData && this.state.projectData.map((section, sidx) => {
            const bgStyle = {
              background: section.background,
              paddingTop: section.padding + "px",
              paddingBottom: section.padding + "px"
            }
            return (
              <div className="section" style={bgStyle}>
                {section.nodes.map((node, idx) => {
                  if (node.type.includes("hero")) {
                    return (
                      <Hero sectionData={node} />
                    );
                  }
                  else if (node.type === "description") {
                    return (
                      <Description alignment={node.alignment} title={node.title} paragraph={node.paragraph} animation={node.animation} size={node.size} icon={node.icon} />
                    )
                  }
                  else if (node.type === "card-full") {
                    return (
                      <FullCard cardData={node} id={"card-full-" + idx} />
                    )
                  }
                  else if (node.type === "card-dual") {
                    return (
                      <CardDual data={node} id={"card-dual-" + idx} />
                    );
                  }
                  else if (node.type === "phoneFade") {
                    return (
                      <PhoneFade phoneType={node.phoneType} details={node.details} images={node.images} />
                    )
                  }
                  else if (node.type === "small-info")  {
                    return (
                      <SmallInfo text={node.text} />
                    );
                  }
                  else if (node.type === "safariView") {
                    return (
                      <SafariView data={node} id={"safari-view-" + idx} />
                    );
                  }
                  else if (node.type === "fullImage") {
                    return (
                      <FullImage data={node} id={"full-image-" + idx} />
                    );
                  }
                  else if (node.type === "halfImage") {
                    return (
                      <HalfImage data={node} id={"half-image-" + idx} />
                    );
                  }
                  else if (node.type === "tallImage") {
                    return (
                      <TallImage data={node} id={"tall-image-" + idx} />
                    );
                  }
                  else if (node.type === "video") {
                    return (
                      <Video data={node} id={"video-" + idx} />
                    );
                  }
                  else if (node.type === "large-quote") {
                    return (
                      <LargeQuote data={node} />
                    );
                  }
                  else if (node.type === "footer") {
                    return (
                      <Footer />
                    );
                  }
                  else {
                    return ("undefined")
                  }
                })}
              </div>
            )
          })}
        </div>
      </>
    );
  }
}
