import React from 'react';

export default class Hero extends React.Component {
  constructor(props) {
    super(props);
    this.togglePlay = this.togglePlay.bind(this);
    this.changeImg = this.changeImg.bind(this);
    this.state = {
      playing: false,
      img: window.innerWidth <= 700 ? this.props.sectionData.mobileSrc : this.props.sectionData.src
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.changeImg);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.changeImg);
  }

  changeImg() {
    this.setState({
      img: window.innerWidth <= 700 ? this.props.sectionData.mobileSrc : this.props.sectionData.src
    });
  }

  togglePlay() {
    const video = document.getElementById("hero-video");
    if (video.hasAttribute("controls")) {
      video.removeAttribute("controls");
      video.pause();
    } else {
       video.setAttribute("controls", "controls");
       video.play();
    }
    this.setState({ playing: !this.state.playing });
  }

  render() {
    const data = this.props.sectionData;

    const btnStyle = {
      opacity: this.state.playing ? 0.0 : 0.9,
    }

    const heroPadding = {
      padding: data.padding + "px",
    }

    return (
      <div className="hero">
        <div className="hero-media-holder">
          {data.type.includes("video") ?
          <>
            <video id="hero-video" className="hero-media-video" style={heroPadding}>
              <source src={data.src} type="video/mp4"></source>
              Your browser does not support the video tag.
            </video>
            <div className="play-button-holder">
              <img style={btnStyle} className="play-button" src="/img/icons/play-button-white.png" alt="Play" onClick={this.togglePlay} />
            </div>
          </>
          : <img className="hero-media-img" style={heroPadding} src={this.state.img} alt={data.srcAlt} />
          }
        </div>

        <div className="title-holder">
          <h1 className="hero-title">{data.title}</h1>
        </div>
      </div>
    )
  }
}
