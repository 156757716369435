import React from 'react';

export default class TallImage extends React.Component {
  constructor(props) {
    super(props);
    this.triggerAnimation = this.triggerAnimation.bind(this);
    this.state = {
      animation: false
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.triggerAnimation);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.triggerAnimation);
  }
  triggerAnimation() {
    // when it hits the middle 50 of viewport, transform up by adding classname
    const el = document.getElementById(this.props.id);
    let bounds = el.getBoundingClientRect();
    let mid50bottom = window.innerHeight - (window.innerHeight / 6);
    let mid50top = window.innerHeight / 6;
    let detailTop = bounds.top;
    let bottom60 = window.innerHeight - (window.innerHeight / 6);

    // on desktop, we use the middle 70ish% to fade in or out
    if (window.innerWidth >= 900) {
      if (((detailTop > mid50top) && (detailTop < mid50bottom)) || (detailTop < mid50top)) {
        this.setState({ animation: true });
      }
    }
    // on mobile, once it hits middle of screen we fade in
    else {
      if (detailTop <= bottom60) {
        this.setState({ animation: true });
      }
    }
  }


  render() {
    const data = this.props.data;

    let classes = "tall-image-main ";
    classes += this.state.animation ? "image-animated" : "";

    return (
      <div className="tall-image-holder">
        <div className={classes} id={this.props.id}>
          <img className="tall-image" src={data.src} alt={data.alt} />
        </div>
      </div>
    )
  }
}
