import React from 'react';

export default class Phone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {

  }
  componentWillUnmount() {
  }

  render() {

    let classes = "phone ";
    classes += this.props.shown ? "shown " : "hidden ";
    classes += this.props.type === "iphoneX" ? "iphoneX-outline" :"iphone8-outline";

    const bgStyle = {
      backgroundImage: "url('" + this.props.image + "')",
    };

    return (
      <div id={this.props.id} className={classes}>
        <div className="phone-inner" style={bgStyle}></div>
      </div>
    );
  }
}
