import React from 'react';

export default class ScrollIndicator extends React.Component {
  constructor(props) {
    super(props);
    this.hideIndicator = this.hideIndicator.bind(this);
    this.state = {
      indicator: true
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.hideIndicator);
  }

  hideIndicator() {
    const scrollAmount = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
    if (scrollAmount >= (window.innerHeight / 2)) {
      this.setState({ indicator: false });
      window.removeEventListener("scroll", this.hideIndicator);
    }
  }
  
  render() {
    const classes = this.state.indicator ? "down-arrow animate " : "down-arrow ";
    const positionStyle = {
      bottom: window.innerHeight > 700 ? "40px" : "20px"
    }
    return (
      <div className="scroll-indicator" style={positionStyle}>
        <img className={classes + " arrow-top"} alt={"Scroll down for more information"} src="/img/icons/down.png" />
        <img className={classes + " arrow-bottom"} alt={"Scroll down for more information"} src="/img/icons/down.png" />
      </div>
    )
  }
}
