import React from 'react';
import { Helmet } from 'react-helmet';

// title, thumbnail (url), thumbnailAlt, pageDescription, url (the url address of the page)
function SEO(props) {
    const {title, thumbnail, thumbnailAlt, pageDescription, url} = props;
    return (
        <Helmet>
            {/* Titles */}
            <title>{title}</title>
            <meta property="og:title" content={title} />
            <meta itemprop="name" content={title} />
            <meta name="twitter:title" content={title} />

            {/* Images */}
            <meta name="image" content={thumbnail} />
            <meta property="og:image" content={thumbnail} />
            <meta itemprop="image" content={thumbnail} />
            <meta name="twitter:image" content={thumbnail} />


            {/* URLs */}
            <meta property="og:url" content={url} />

            {/* Descriptions */}
            <meta name="description" conent={pageDescription} />
            <meta name="og:description" content={pageDescription} />
            <meta itemprop="description" content={pageDescription} />
            <meta name="twitter:description" content={pageDescription} />

            {/* Miscellaneous */}
            <meta property="og:site_name" content="Jordan B. Wolff" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:image:alt" content={thumbnailAlt}></meta>
        </Helmet>
    )
}

export default SEO;