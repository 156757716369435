import React from 'react';

export default class CardDual extends React.Component {
  constructor(props) {
    super(props);
    this.triggerAnimation = this.triggerAnimation.bind(this);
    this.renderCards = this.renderCards.bind(this);
    this.state = {
      animation: false,
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.triggerAnimation);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.triggerAnimation);
  }
  triggerAnimation() {
    // when it hits the middle 50 of viewport, transform up by adding classname
    const card = document.getElementById(this.props.id);
    let bounds = card.getBoundingClientRect();
    let mid50bottom = window.innerHeight - (window.innerHeight / 4);
    let mid50top = window.innerHeight / 4;
    let detailTop = bounds.top;
    let half = window.innerHeight / 2;

    // on desktop, we use the middle 50% to fade in or out
    if (window.innerWidth >= 900) {
      if (((detailTop > mid50top) && (detailTop < mid50bottom)) || (detailTop < mid50top)) {
        this.setState({ animation: true });
      }
    }
    // on mobile, once it hits middle of screen we fade in
    else {
      if (detailTop <= half) {
        this.setState({ animation: true });
      }
    }
  }

  renderCards() {
    const data = this.props.data;
    let renderData = [];
    for (let i = 0; i < data.cardTitles.length; ++i) {
      renderData.push(
        <div className="dual-card">
          <div className="card-left">
            <div className="icon-holder">
              {data.cardIcons[i] && <img className="card-icon" src={data.cardIcons[i]} alt="" />}
              {data.iconTitles[i] && <h2 className="icon-title">{data.iconTitles[i]}</h2>}
            </div>
            <h1 className="card-title">{data.cardTitles[i]}</h1>
            <p className="card-p">{data.descriptions[i]}</p>
          </div>
          {data.images[i] && <div className="card-right">
             <img className="card-image" src={data.images[i]} alt="" />
          </div>}
        </div>
      );
    }
    return renderData;
  }

  render() {
    let classes = "card-dual ";
    classes += this.state.animation ? "card-animated" : "";

    return (
      <div className={classes} id={this.props.id}>
        {this.renderCards()}
      </div>
    )
  }
}
