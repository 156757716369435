import React from 'react';
import { Link } from 'react-router-dom';
import navData from "../data/nav.json";

export default class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.toggleNav = this.toggleNav.bind(this);
    this.hideNav = this.hideNav.bind(this);
    this.clearMenu = this.clearMenu.bind(this);
    this.preventDefault = this.preventDefault.bind(this);
    this.preventDefaultForScrollKeys = this.preventDefaultForScrollKeys.bind(this);
    this.disableScroll = this.disableScroll.bind(this);
    this.enableScroll = this.enableScroll.bind(this);

    // left: 37, up: 38, right: 39, down: 40,
    // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
    this.keys = {37: 1, 38: 1, 39: 1, 40: 1};

    this.state = {
      navShown: false,
      navData: navData.navLinks
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.clearMenu);
    this.setState({
      navShown: false
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.clearMenu);
  }
  clearMenu() {
    if (window.innerWidth > 700) {
      this.setState({
        navShown: false
      });
    }
    this.enableScroll();
  }
  toggleNav() {
    if (this.state.navShown) {
      this.enableScroll()
    }
    else {
      this.disableScroll();
    }
    // switch
    this.setState({
      navShown: !this.state.navShown
    });
  }
  hideNav() {
    this.setState({
      navShown: false
    });
    this.enableScroll();
  }
  render() {
    let navIconClasses = this.state.navShown ? "nav-icon active" : "nav-icon";
    let navBarClasses = this.state.navShown ? "navbar active" : "navbar";
    return (
      <div className={navBarClasses}>
        <div className="nav-inner">
          <ul className="nav-links">
            {this.state.navData.map((navItem, idx) => {
              if (navItem.type === "text") {
                return (
                  <li className="nav-item" onClick={this.hideNav}><Link className="nav-link" to={navItem.link}>{navItem.text}</Link></li>
                )
              }
              else if (navItem.type === "logo") {
                return (
                  <li className="nav-item" onClick={this.hideNav}><Link className="nav-link" to={navItem.link}><img className="nav-logo" src={navItem.src} alt={"logo"}/></Link></li>
                )
              }
              else if (navItem.type === "contact") {
                return (
                  <li className="nav-item" onClick={this.hideNav}><a className="nav-link" href={navItem.link}>{navItem.text}</a></li>
                )
              }
              else if (navItem.type === "external-text") {
                return (
                  <li className="nav-item external" onClick={this.hideNav}><a className="nav-link" target="_blank" rel="noopener noreferrer" href={navItem.link}>{navItem.text}</a></li>
                )
              }
              else {
                return (<li></li>)
              }
            })
            }
          </ul>
          <div className={navIconClasses} onClick={this.toggleNav}>
            <div className="nav-line top"></div>
            <div className="nav-line bot"></div>
          </div>
        </div>
      </div>
    )
  }

  preventDefault(e) {
    e = e || window.event;
    if (e.preventDefault) {
      e.preventDefault();
    }
    e.returnValue = false;
  }
  preventDefaultForScrollKeys(e) {
    if (this.keys[e.keyCode]) {
      this.preventDefault(e);
      return false;
    }
  }
  disableScroll() {
    document.addEventListener('wheel', this.preventDefault, {passive: false}); // Disable scrolling in Chrome
    window.ontouchmove  = this.preventDefault; // mobile
    document.onkeydown  = this.preventDefaultForScrollKeys;
  }
  enableScroll() {
    document.removeEventListener('wheel', this.preventDefault, {passive: false}); // Enable scrolling in Chrome
    window.ontouchmove = null;
    document.onkeydown = null;
  }
}
