import React from 'react';

export default class LargeQuote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const data = this.props.data;
    return (
      <div className="large-quote-holder">
        <div className="large-quote-inner">
          <h1 className="quote-mark-begin">"</h1>
          {data.text.map((item, idx) => {
            return (
              <h2 className="quote">{item}</h2>
            )
          })}
          <h1 className="quote-mark-end">"</h1>
        </div>
      </div>
    )
  }
}
